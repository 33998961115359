/* InicialScreen.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f8eaec;
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alinha itens no topo do container */
  height: 100vh;
  flex-wrap: wrap;
}

.column {
  padding: 20px;
  text-align: center;
}

.column-1 {
  flex: 1; /* Flexível para ocupar o espaço disponível */
  margin: 20px;
  order: 1;
}

.column-3 {
  flex: 1; /* Flexível para ocupar o espaço disponível */
  margin: 20px;
  order: 3;
}

.column-2 {
  width: 200px; /* Tamanho fixo para a coluna 2 */
  order: 2;
}

@media screen and (max-width: 768px) {
  .column {
    flex: 1 1 100% !important;
  }
  
  .column-1 {
    order: 3 !important;
  }
  
  .column-3 {
    order: 3 !important;
  }
  
  .column-2 {
    order: 2;
  }
}


.image-container {
  display:flex;
  flex-direction: column;
  position: relative;
  width: auto;
  /*height: 500px;*/
  overflow: hidden;
  margin-bottom: 20px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 2;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
.brand{
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
}
.brand img{
  width: 250px;
  height: 250px;
}
.message-list {
  flex-wrap: wrap; /* Permite que os itens se ajustem em várias linhas */
  width: 45%; /* Ajuste conforme necessário */
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.message-list .message-list-content {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens se ajustem em várias linhas */
}

.message-item {
  flex: 1 1 100%; /* Distribui os itens igualmente em duas colunas */
  margin: 0; /* Espaçamento entre os itens da lista */
  border-bottom: solid 1px #fff;
  padding: 5px; /* Espaçamento interno dos itens da lista (opcional) */
  background-image: url("./Components/check.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 23px 6px;
}

.message-item p{
  color: #fff;
  margin: 0;
}

.message-reveal p{
  color: #fff;
  margin: 10px;
}


.message-list-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  flex-basis: 100%; /* Define a largura total para o título */
  text-align: center; /* Centraliza o texto do título */
}

.menino {
  background-color: #39b5fd;
  background-image: url("./Components/boy.png");
  background-repeat: no-repeat;
  background-size: 64px;
  background-position: 20px 14px;
  color: white;
  height: 88.5%;
}

.menina {
  background-color: #ed62b3;
  background-image: url("./Components/girl.png");
  background-repeat: no-repeat;
  background-size: 64px;
  background-position: 16px 14px;
  color: white;
  height: 88.5%;
}

.message-item {
  padding: 5px;
}
