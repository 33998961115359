/* Mensagem.css */
body{
  background-color: #f8eaec;
}
.message-home{
  margin: 15px 0;
}
.message-home img{
  width: 180px;
  height: 180px;
}
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.container h2{
  padding-left: 85px;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.radio-label {
  margin-right: 15px;
}

.button-container {
  display: flex;
}

.button-apuracao-menina {
  background-color: white !important;
  color: #ed62b3 !important;
}


.button-apuracao-menino {
  background-color: white !important;
  color: #39b5fd !important;
}

.button {
  margin: 0 20px;
  background: transparent;
  color: white;
  border: solid 3px #fff;
  width: 50%;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 100px;
}

.button:hover {
  border: solid 3px #55565b;
  color: #55565b;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.successMessage {
  color: green;
  font-size: 14px;
  margin-top: 5px;
}
